import React, { FC } from 'react';

import { Schema as UISchema } from './schema.styles';
import { SchemaProps } from './schema.types';

export const Schema: FC<SchemaProps> = ({
  backgroundColor,
  className,
  withoutTail = false,
  children,
}) => (
  <UISchema
    $backgroundColor={backgroundColor}
    $withoutTail={withoutTail}
    variant="caption"
    className={className}
  >
    {children}
  </UISchema>
);
