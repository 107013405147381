import { request } from '@keaze/web/api';
import { Methods } from '@keaze/web/api/api.types';
import { PROFILE_QUERY } from '../../profile';
import { FAVORITES_QUERY } from '../favorites';
import { AddFavorite } from './addFavorite.types';

export const addFavorite: AddFavorite = async (token, listingId) =>
  await request({
    method: Methods.POST,
    query: `${PROFILE_QUERY}/${FAVORITES_QUERY}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      listing: listingId,
    },
  });
