import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const Status = styled(Typography)`
  ${({ theme: { palette, colors } }) => `
    color: ${palette.text.primary};
    display: block;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    padding: 4px 5px;
    text-transform: uppercase;
    background-color: ${colors.greys.greys0};
  `}
`;
