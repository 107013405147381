import styled from 'styled-components';

import { WithMyTheme } from '@keaze/web/theme';
import { ClipPathPosition } from './section.types';

interface SectionProps extends WithMyTheme {
  $clipPathPosition?: ClipPathPosition;
}

export const UISection = styled.section<SectionProps>`
  ${({
    theme: { palette, spacing, breakpoints, cutCorner },
    $clipPathPosition = 'topLeft',
  }) => `
    position: relative;
    margin-bottom: ${spacing(2)}px;
    padding: 20px 0;
    z-index: 1;

    ${breakpoints.up('sm')} {
      padding: 30px 0;
    }

    ${breakpoints.up('md')} {
      padding: 35px 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      ${$clipPathPosition === 'topLeft' ? 'left' : 'right'}: -${spacing(2)}px;
      width: 100vw;
      height: 100%;
      background-color: ${palette.common.white};
      z-index: -1;

      ${breakpoints.up('sm')} {
        ${$clipPathPosition === 'topLeft' ? 'left' : 'right'}: -${spacing(3)}px;
      }

      ${breakpoints.up('lg')} {
        ${$clipPathPosition === 'topLeft' ? 'left' : 'right'}: -${spacing(6)}px;
        clip-path: ${cutCorner[$clipPathPosition].big};
      }
    }
  `}
`;
