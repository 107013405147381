import React, { FC } from 'react';
import NextHead from 'next/head';

import { config } from '@keaze/web/config';
import { useRouter } from 'next/router';

export const DEFAULT_SOCIAL_TITLE =
  'Search Help to Buy, Shared Ownership &amp; Rent to Buy properties | Keaze';

export const DEFAULT_SOCIAL_DESCRIPTION =
  'Search and book an appointment to view new homes for sale with Shared ownership, Help to Buy and London Living Rent.';

type Props = {
  title?: string;
  withBaseSocialMetaTags?: boolean;
};

type SocialMetaTags = (vercelUrl: string) => JSX.Element;

const socialMetaTags: SocialMetaTags = (vercelUrl) => (
  <>
    <meta
      property="og:image"
      content={`${vercelUrl}/images/sharing-image.png`}
    />
    <meta property="og:image:type" content="image/png" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:alt" content="We’re making home hunting easier" />
    <meta property="og:title" content={DEFAULT_SOCIAL_TITLE} />
    <meta property="og:description" content={DEFAULT_SOCIAL_DESCRIPTION} />
  </>
);

export const Head: FC<Props> = ({
  children,
  title = 'Keaze',
  withBaseSocialMetaTags = false,
}) => {
  const { domainUrl = '' } = config;
  const router = useRouter();

  return (
    <>
      <NextHead>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />

        <meta property="og:site_name" content="Keaze" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${domainUrl}${router.asPath}`} />
        {withBaseSocialMetaTags && socialMetaTags(domainUrl)}

        <title>{title}</title>

        {children}
      </NextHead>
    </>
  );
};
