import React, { FC, ReactNode, memo, useState, useEffect } from 'react';
import { InputAdornment, makeStyles, Box } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { AutocompleteRenderInputParams } from '@material-ui/lab';

import {
  Autocomplete,
  IconButton,
  Label,
  TextField,
} from './googleSearchAutocomplete.styles';
import { CustomPaper } from './customPaper';
import { useGoogleSearchAutocomplete } from './googleSearchAutocomplete.hook';
import {
  GoogleSearchAutocompleteProps,
  GoogleSearchAutocompleteOption,
} from './googleSearchAutocomplete.types';

const NO_OPTIONS_TEXT = 'Start typing name or postal code...';

type RenderOption = (
  option: GoogleSearchAutocompleteOption,
  onClickOption: (option: string) => void
) => ReactNode;

type RenderStartAdornment = (withIcon: boolean) => JSX.Element | null;

type RenderEndAdornment = (
  inputValue: string,
  onClearInput: () => void
) => JSX.Element;

const useStyles = makeStyles(({ palette }) => ({
  option: {
    '&.MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: 'transparent',
      '& > *': {
        color: palette.action.hover,
      },
    },
  },
}));

const LocationIcon = () => (
  <Box m={1}>
    <svg
      width="15"
      height="21"
      viewBox="0 0 15 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 7.7474C14 10.3625 12.3074 13.4468 10.4419 16.0064C9.53005 17.2574 8.61603 18.3312 7.92929 19.0927C7.77328 19.2656 7.62928 19.4222 7.5 19.5607C7.37072 19.4222 7.22672 19.2656 7.07071 19.0927C6.38397 18.3312 5.46995 17.2574 4.55813 16.0064C2.69259 13.4468 1 10.3625 1 7.7474C1 3.99007 3.94049 1 7.5 1C11.0595 1 14 3.99007 14 7.7474Z"
        strokeWidth="2"
      />
      <circle cx="7.5" cy="7.5" r="1.5" strokeWidth="2" />
    </svg>
  </Box>
);

const renderOption: RenderOption = ({ label, value }, onClickOption) => (
  <Label onClick={() => onClickOption(value)}>{label}</Label>
);

const renderStartAdornment: RenderStartAdornment = (withIcon) => {
  if (withIcon) {
    return (
      <InputAdornment position="start">
        {withIcon && <LocationIcon />}
      </InputAdornment>
    );
  }

  return null;
};

const renderEndAdornment: RenderEndAdornment = (inputValue, onClearInput) => (
  <InputAdornment position="end">
    {inputValue.length > 0 && (
      <IconButton size="small" onClick={onClearInput}>
        <Clear />
      </IconButton>
    )}
  </InputAdornment>
);

// TODO: fix console warning "You can use the `getOptionSelected` prop to customize the equality test."
export const GoogleSearchAutocomplete: FC<GoogleSearchAutocompleteProps> = memo(
  ({ value = '', withIcon = false, onChange }) => {
    const classes = useStyles();
    const {
      optionsOpen,
      inputValue,
      options,
      isLoading,
      error,
      getOptionLabel,
      onToggleOptions,
      onClickOption,
      onKeyDown,
      onClearInput,
      onChangeInput,
    } = useGoogleSearchAutocomplete(value, onChange);

    // Updating TextInput styles after SSR (bug in the styled-components)
    const [isValue, setIsValue] = useState<boolean>(false);

    useEffect(() => {
      setIsValue(!!value);
    }, [value]);

    return (
      <Autocomplete<any>
        fullWidth
        blurOnSelect
        classes={classes}
        open={optionsOpen}
        value={inputValue}
        onFocus={onToggleOptions}
        onBlur={onToggleOptions}
        options={options}
        getOptionLabel={getOptionLabel}
        loading={isLoading}
        PaperComponent={CustomPaper}
        popupIcon={null}
        noOptionsText={NO_OPTIONS_TEXT}
        renderOption={(option: GoogleSearchAutocompleteOption) =>
          renderOption(option, onClickOption)
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            $isValue={isValue}
            autoComplete="off"
            placeholder="Ashford, Kent or TN23"
            value={inputValue}
            onChange={onChangeInput}
            onKeyDown={onKeyDown}
            error={!!error}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              startAdornment: renderStartAdornment(withIcon),
              endAdornment: renderEndAdornment(inputValue, onClearInput),
            }}
          />
        )}
      />
    );
  }
);
