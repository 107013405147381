import React, { FC, memo } from 'react';

import { Status as UIStatus } from './status.styles';
import { StatusProps } from './status.types';

const DEFAULT_NAME = 'new home';

export const Status: FC<StatusProps> = memo(
  ({ name = DEFAULT_NAME, ...rest }) => <UIStatus {...rest}>{name}</UIStatus>
);
