import React, { FC } from 'react';

import { UISection } from './section.styles';
import { SectionProps } from './section.types';

export const Section: FC<SectionProps> = ({ children, position, ...rest }) => (
  <UISection $clipPathPosition={position} {...rest}>
    {children}
  </UISection>
);
