import styled from 'styled-components';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

import { WithMyTheme } from '@keaze/web/theme';

interface TitleProps extends WithMyTheme {
  $titlePosition: 'left' | 'center' | 'right';
}

export const Title = styled(Typography)<TitleProps & TypographyProps>`
  ${({ theme: { breakpoints }, $titlePosition }: TitleProps) => `
    text-align: ${$titlePosition};
    padding-bottom: 15px;

    ${breakpoints.up('md')} {
      padding-bottom: 20px;
    }
  `}
`;
