import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MUIList from '@material-ui/core/List';
import MUIListItem, { ListItemTypeMap } from '@material-ui/core/ListItem';
import { fade } from '@material-ui/core/styles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

export const Banner = styled(Box)`
  ${({ theme: { colors, breakpoints, cutCorner } }) => `
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		${breakpoints.up('sm')} {
			padding: 20px;
			clip-path: ${cutCorner.topRight.small};
    	background-color: ${fade(colors.greys.greys2, 0.9)};
		}

		${breakpoints.up('lg')} {
			padding: 30px;
		}
	`}
`;

export const Info = styled(Box)`
  margin-right: 8px;
`;

export const Title = styled(Typography)`
  ${({
    theme: {
      typography: { pxToRem },
    },
  }) => `
		font-size: ${pxToRem(20)};
		line-height: ${pxToRem(25)};
		font-weight: 700;
  	margin-bottom: 10px;
	`}
`;

export const Subtitle = styled(Typography)`
  opacity: 0.7;
`;

export const Icon = styled(Box)`
  flex: 0 0 auto;
`;

export const GetResultsButton = styled(Button)`
  text-align: center;
`;

export const List = styled(MUIList)`
  margin: 10px 0 20px 0;
  padding: 0 0 0 18px;
  list-style: disc;
  flex: 1 1 auto;
`;

export const ListItem: OverridableComponent<
  ListItemTypeMap<{ button?: false }, 'li'>
> = styled(MUIListItem)`
  display: list-item;
  padding: 7px 0;
`;
