import { useMutation } from 'react-query';

import { Token } from '@keaze/web/common/interfaces';
import {
  addFavorite,
  ExpectedAddFavorite,
} from '@keaze/web/api/profile/favorites/addFavorite';

type Args = {
  token: Token;
  listingId: number;
};

export const useAddFavoriteMutation = () =>
  useMutation<ExpectedAddFavorite, Error, Args>(({ token, listingId }) =>
    addFavorite(token, listingId)
  );
