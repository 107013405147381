import { FC, memo } from 'react';
import NextImage from 'next/image';
import NextLink from 'next/link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles';

import {
  Banner,
  Info,
  Title,
  Subtitle,
  Icon,
  GetResultsButton,
  List,
  ListItem,
} from './personalizedSearchBanner.styles';
import { GTMabTestPersonalizedSearch } from '@keaze/web/libs/GTM';

const PERSONAL_INFORMATION_PATH = '/personal-information';

const INFO = [
  'Your eligibility',
  'Where you want to live',
  'What you potentially could afford',
];

export const PersonalizedSearchBanner: FC = memo(() => {
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const handleClick = () => {
    GTMabTestPersonalizedSearch('view_eligibility_params_form', 'banner');
  };

  return (
    <Banner>
      <Grid
        container
        justify="space-between"
        alignItems="flex-start"
        wrap="nowrap"
      >
        <Info>
          <Title>Personalised search</Title>
          <Subtitle variant="body2">
            Get tailored results based on your needs and circumstances:
          </Subtitle>
        </Info>
        {isMedium && (
          <Icon>
            <NextImage
              src="/svg/personalised-search-banner.svg"
              alt="personalised search banner"
              width={60}
              height={44}
            />
          </Icon>
        )}
      </Grid>
      <List disablePadding>
        {INFO.map((text, key) => (
          <ListItem key={key} disableGutters>
            <Typography variant="body2">{text}</Typography>
          </ListItem>
        ))}
      </List>
      <NextLink href={PERSONAL_INFORMATION_PATH} prefetch={false} passHref>
        <GetResultsButton
          color="secondary"
          variant="contained"
          fullWidth
          size="small"
          onClick={handleClick}
        >
          Get personalised results
        </GetResultsButton>
      </NextLink>
    </Banner>
  );
});
