import React, { FC, memo } from 'react';
import Box from '@material-ui/core/Box';

import { CardGroupProps } from './cardGroup.types';
import { Title } from './cardGroup.styles';
import { List } from './list';

export const CardGroup: FC<CardGroupProps> = memo(
  ({
    data,
    title,
    titlePosition = 'left',
    isLoading = false,
    className,
    ...listProps
  }) => {
    if (!isLoading && data.length === 0) {
      return null;
    }

    return (
      <Box className={className}>
        {title && (
          <Title $titlePosition={titlePosition} variant="h2">
            {title}
          </Title>
        )}
        <List data={data} isLoading={isLoading} {...listProps} />
      </Box>
    );
  }
);
