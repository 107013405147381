import React, { FC, memo } from 'react';
import Grid, { GridProps } from '@mui/material/Grid';

import { PersonalizedSearchBanner } from '@keaze/web/components/personalizedSearchBanner';
import { SaleCard, SaleCardSkeleton } from '@keaze/web/components/saleCard';
import { ListProps } from './list.types';

const DEFAULT_SPACING = { xs: 4 };

export const List: FC<ListProps> = memo(
  ({
    data,
    GridItemProps,
    spacing,
    openInNewTab = false,
    priority = false,
    withFavorite,
    withoutImageOptimize = false,
    withPersonalizedBanner = false,
    isLoading = false,
    onChange,
    ...gridProps
  }) => {
    const defaultGridItemProps: GridProps = { xs: 12, md: 6, lg: 4 };
    const customGridItemProps = { ...defaultGridItemProps, ...GridItemProps };

    return (
      <Grid container spacing={spacing ?? DEFAULT_SPACING} {...gridProps}>
        {withPersonalizedBanner && !isLoading && (
          <Grid item {...customGridItemProps}>
            <PersonalizedSearchBanner />
          </Grid>
        )}
        {data.map((listing, key) => {
          if (listing === null) {
            return (
              <Grid key={key} item {...customGridItemProps}>
                <SaleCardSkeleton />
              </Grid>
            );
          }

          return (
            <Grid key={listing.id} item {...customGridItemProps}>
              <SaleCard
                {...listing}
                isLoading={isLoading}
                withFavorite={withFavorite}
                withoutImageOptimize={withoutImageOptimize}
                openInNewTab={openInNewTab}
                priority={priority}
                onChange={onChange}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
);
