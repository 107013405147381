import { useCallback, useContext, useEffect, useState } from 'react';

import { useAddFavoriteMutation } from '@keaze/web/hooks/queries/profile/useAddFavorite';
import { useDeleteFavoriteMutation } from '@keaze/web/hooks/queries/profile/useDeleteFavorite';
import { AuthContext } from '@keaze/web/contexts/auth';
import {
  GTMAddedToFavorites,
  GTMDeletedFromFavorites,
} from '@keaze/web/libs/GTM';
import { PersonalizedSearchForm, Scheme } from '@keaze/web/common/interfaces';
import { useLocalStorage } from './useLocalStorage';
import { LocalStorageKeys } from '@keaze/web/common/enums';
import { DEFAULT_PERSONALIZED_SEARCH_DATA } from '../utils/defaultValues';

type UseFavoriteExpected = {
  favorite: boolean;
  deletedFavorite: boolean;
  onFavoriteClick: () => void;
};

type UseSaleCardArgs = {
  listingId: number;
  listingSlug: string;
  listingSchemes: Scheme[];
  favourite: boolean | null;
};

type UseFavorite = (args: UseSaleCardArgs) => UseFavoriteExpected;

export const useFavorite: UseFavorite = ({
  listingId,
  listingSlug,
  listingSchemes,
  favourite,
}) => {
  const [favorite, setFavorite] = useState<boolean>(!!favourite);
  const { token, isAuthenticated, toggleAuthDialog } = useContext(AuthContext);
  const { mutate: addFavoriteMutate } = useAddFavoriteMutation();
  const {
    isSuccess: isDeleteFavoriteSuccess,
    mutate: deleteFavoriteMutate,
  } = useDeleteFavoriteMutation();
  const [
    { householdIncome, estimatedDeposit },
  ] = useLocalStorage<PersonalizedSearchForm>(
    LocalStorageKeys.personalizedSearch,
    DEFAULT_PERSONALIZED_SEARCH_DATA
  );

  useEffect(() => {
    setFavorite(!!favourite);
  }, [favourite]);

  const handleFavoriteClick = useCallback(() => {
    if (isAuthenticated) {
      if (favorite) {
        setFavorite(false);
        GTMDeletedFromFavorites({
          listingSchemes,
          listingSlug,
          householdIncome,
          estimatedDeposit,
        });
        return deleteFavoriteMutate({ token, listingId });
      }

      setFavorite(true);
      GTMAddedToFavorites({
        listingSchemes,
        listingSlug,
        householdIncome,
        estimatedDeposit,
      });

      return addFavoriteMutate({ token, listingId });
    }

    return toggleAuthDialog({ triggeredBy: 'favorite' });
  }, [
    isAuthenticated,
    favorite,
    householdIncome,
    estimatedDeposit,
    listingId,
    listingSchemes,
    listingSlug,
    token,
    toggleAuthDialog,
    addFavoriteMutate,
    deleteFavoriteMutate,
  ]);

  return {
    favorite,
    deletedFavorite: isDeleteFavoriteSuccess,
    onFavoriteClick: handleFavoriteClick,
  };
};
