import { request } from '../../api';
import { GoogleSearch } from './googleSearch.types';
import { GoogleSearchResponse } from '@keaze/web/common/interfaces';

const GOOGLE_SEARCH_QUERY = 'google-search';

export const googleSearch: GoogleSearch = async ({ value, host, apiKey }) => {
  const { predictions }: GoogleSearchResponse = await request({
    query: `${GOOGLE_SEARCH_QUERY}`,
    params: {
      input: value,
      key: apiKey,
      language: 'en-GB',
      types: ['cities', 'address'],
      components: 'country:gb',
    },
    host,
  });

  return predictions.map(({ description }) => description);
};
