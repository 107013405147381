import { request } from '@keaze/web/api';
import { Methods } from '@keaze/web/api/api.types';
import { PROFILE_QUERY } from '../../profile';
import { FAVORITES_QUERY } from '../favorites';
import { DeleteFavorite } from './deleteFavorite.types';

export const deleteFavorite: DeleteFavorite = async (token, listingId) =>
  await request({
    method: Methods.DELETE,
    query: `${PROFILE_QUERY}/${FAVORITES_QUERY}/${listingId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
