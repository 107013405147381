import styled from 'styled-components';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

import { WithMyTheme } from '@keaze/web/theme';

interface Props extends WithMyTheme {
  $backgroundColor: string;
  $withoutTail: boolean;
}

export const Schema = styled(Typography)<TypographyProps & Props>`
  ${({ theme: { palette }, $backgroundColor, $withoutTail }: Props) => `
    position: relative;
    display: block;
    color: ${palette.common.white};
    font-weight: 700;
    padding: 5px 10px;
    background: ${$backgroundColor || palette.primary.main};

    ${
      !$withoutTail
        ? `
            &::after {
              content: '';
              position: absolute;
              right: 0;
              bottom: -6px;
              width: 6px;
              height: 6px;
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), ${
                $backgroundColor || palette.primary.main
              };
              clip-path: polygon(0 0, 0% 100%, 100% 0);
            }
          `
        : ''
    }
  `}
`;
