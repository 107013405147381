import styled from 'styled-components';
import { IconButton as MUIIconButton, Typography } from '@material-ui/core';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';

import { WithMyTheme } from '@keaze/web/theme';
import {
  TextField as CPTextField,
  TextFieldProps as CPTextFieldProps,
} from '@keaze/web/components/textField';

interface TextFieldProps extends WithMyTheme {
  $isValue: boolean;
}

export const Autocomplete = styled(MuiAutocomplete)`
  &.Mui-focused .MuiFilledInput-notchedOutline,
  &:hover .MuiFilledInput-notchedOutline {
    border-color: ${({ theme: { colors } }) => colors.greys.greys2};
  }

  .MuiAutocomplete-inputRoot {
    padding: ${({ theme: { spacing } }) => spacing(0, 1)};
  }

  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root']
    .MuiAutocomplete-input {
    padding: 7px 4px;
  }
`;

export const IconButton = styled(MUIIconButton)`
  position: absolute;
  right: ${({ theme: { spacing } }) => spacing(1.5)}px;
`;

export const Label = styled(Typography)`
  ${({
    theme: {
      transitions: { easing, duration },
    },
  }) => `
    font-weight: 500;
    width: 100%;
    padding: 8px 16px;
    transition: ${duration.standard}ms ${easing.easeInOut};
  `}
`;

export const TextField = styled(CPTextField)<TextFieldProps & CPTextFieldProps>`
  ${({
    theme: {
      palette,
      colors,
      transitions: { duration, easing },
    },
    $isValue,
  }) => `
    .MuiInputAdornment-positionStart {
      svg {
        transition: ${duration.standard}ms ${easing.easeInOut};
        stroke: ${$isValue ? palette.secondary.main : colors.greys.greys17};
      }
    } 
  `}
`;
