import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const CustomPaper = styled(Paper)`
  &.MuiAutocomplete-paper {
    margin: 0;
  }

  .MuiAutocomplete-option {
    padding: 0;
  }
`;
