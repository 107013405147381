import {
  ChangeEvent,
  useEffect,
  useState,
  KeyboardEvent,
  useMemo,
} from 'react';

import { useGoogleSearch } from '@keaze/web/hooks/queries/google/useGoogleSearch';
import { useWindowLocation } from '@keaze/web/hooks/useWindowLocation.hook';
import { config } from '@keaze/web/config/config';
import { useDebounce } from '@keaze/web/hooks/useDebounce';
import {
  OnGoogleSearchAutocompleteChange,
  GoogleSearchAutocompleteOption,
} from './googleSearchAutocomplete.types';
import { useLocalStorage } from '@keaze/web/hooks';
import { LocalStorageKeys } from '@keaze/web/common/enums';
import { PostcodeInfo } from '@keaze/web/common/interfaces';

type UseGoogleSearchAutocompleteExpected = {
  optionsOpen: boolean;
  inputValue: string;
  options: GoogleSearchAutocompleteOption[];
  error: Error | null;
  isLoading: boolean;
  getOptionLabel: GetOptionLabel;
  onToggleOptions: () => void;
  onChangeInput: OnChangeInput;
  onClearInput: () => void;
  onClickOption: OnGoogleSearchAutocompleteChange;
  onKeyDown: OnKeyDown;
};

type UseGoogleSearchAutocomplete = (
  value: string,
  onChange: OnGoogleSearchAutocompleteChange
) => UseGoogleSearchAutocompleteExpected;

type OnChangeInput = (e: ChangeEvent<HTMLInputElement>) => void;

type OnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => void;

type GetOptionLabel = (
  option: GoogleSearchAutocompleteOption | string
) => string;

type ConvertGoogleSearchDataToOptions = (
  data: string[]
) => GoogleSearchAutocompleteOption[];

type ConvertPersonalizedLocationDataToOptions = (
  homeLocation: PostcodeInfo | null,
  workLocation: PostcodeInfo | null
) => GoogleSearchAutocompleteOption[];

type GenerateOptionFromPostCodeInfo = (
  postCodeInfo: PostcodeInfo | null,
  addressType: string
) => GoogleSearchAutocompleteOption;

const convertGoogleSearchDataToOptions: ConvertGoogleSearchDataToOptions = (
  data
) => data.map((value) => ({ label: value, value }));

const generateOptionFromPostCodeInfo: GenerateOptionFromPostCodeInfo = (
  postCodeInfo,
  addressType
) => {
  const address = postCodeInfo ? postCodeInfo?.county.name : '';
  const label = `${addressType} (${address})`;

  return { label, value: address };
};

const convertPersonalizedLocationDataToOptions: ConvertPersonalizedLocationDataToOptions = (
  homeLocation,
  workLocation
) => {
  if (homeLocation === null || workLocation === null) {
    return [];
  }

  const homeLocationOption = generateOptionFromPostCodeInfo(
    homeLocation,
    'Home'
  );
  const workLocationOption = generateOptionFromPostCodeInfo(
    workLocation,
    'Work'
  );

  return [homeLocationOption, workLocationOption];
};

export const useGoogleSearchAutocomplete: UseGoogleSearchAutocomplete = (
  value,
  onChange
) => {
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);
  const {
    data = [],
    error,
    isLoading,
    mutate: googleSearchMutate,
  } = useGoogleSearch();
  const location = useWindowLocation();
  const debouncedValue = useDebounce<string>(inputValue, 500);
  const [storedHomeLocation] = useLocalStorage<PostcodeInfo | null>(
    LocalStorageKeys.addressResponse,
    null
  );
  const [storedWorkLocation] = useLocalStorage<PostcodeInfo | null>(
    LocalStorageKeys.workAddressResponse,
    null
  );
  const { googleSearchApiKey } = config;

  const googleSearchOptions = useMemo(
    () => convertGoogleSearchDataToOptions(data),
    [data]
  );
  const personalizedLocationOptions = useMemo(
    () =>
      convertPersonalizedLocationDataToOptions(
        storedHomeLocation,
        storedWorkLocation
      ),
    [storedHomeLocation, storedWorkLocation]
  );
  const options = useMemo(
    () =>
      googleSearchOptions.length === 0
        ? personalizedLocationOptions
        : googleSearchOptions,
    [googleSearchOptions, personalizedLocationOptions]
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (location) {
      googleSearchMutate({
        value: debouncedValue,
        host: location.origin,
        apiKey: googleSearchApiKey,
      });
    }
  }, [debouncedValue, location, googleSearchApiKey, googleSearchMutate]);

  const getOptionLabel: GetOptionLabel = (option) => {
    if (typeof option === 'string') {
      return option;
    }

    return option.label;
  };

  const handleToggleOptions = () => setOptionsOpen((prevState) => !prevState);

  const handleChangeInput: OnChangeInput = ({ target: { value } }) => {
    setInputValue(value);
  };

  const handleClearInput = () => {
    setInputValue('');
    onChange('');
  };

  const handleClickOption = (value: string) => {
    setInputValue(value);
    onChange(value);
  };

  const handleKeyDown: OnKeyDown = ({ key, target }) => {
    if (key === 'Enter') {
      onChange(inputValue);
      (target as HTMLElement).blur();
    }
  };

  return {
    optionsOpen,
    inputValue,
    options,
    error,
    isLoading,
    getOptionLabel,
    onToggleOptions: handleToggleOptions,
    onChangeInput: handleChangeInput,
    onClearInput: handleClearInput,
    onClickOption: handleClickOption,
    onKeyDown: handleKeyDown,
  };
};
