import { useMutation } from 'react-query';

import { Token } from '@keaze/web/common/interfaces';
import {
  deleteFavorite,
  ExpectedDeleteFavorite,
} from '@keaze/web/api/profile/favorites/deleteFavorite';

type Args = {
  token: Token;
  listingId: number;
};

export const useDeleteFavoriteMutation = () =>
  useMutation<ExpectedDeleteFavorite, Error, Args>(({ token, listingId }) =>
    deleteFavorite(token, listingId)
  );
