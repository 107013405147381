import { useMutation } from 'react-query';

import {
  googleSearch,
  GoogleSearchArgs,
} from '@keaze/web/api/google/googleSearch';

export const useGoogleSearch = () =>
  useMutation<string[], Error, GoogleSearchArgs>((args) =>
    googleSearch({ ...args })
  );
